@charset "UTF-8";
@import "org";

@font-face {
  font-family: 'noto';
  src: url('./font/noto.woff') format('woff');
  font-style: normal;
}



/* Color */
$color-theme: #222;
$color-base: #fff;
$color-main: #1276c3;
$color-sub: #f5f5f5;
$color-accent: #d63838;
$color-safety: #52bc25;

$color-border: #222;
$color-border__light: #eee;
$color-text: #333;
$color-icon: #ffb900;


/**********************
	utility
**********************/

//---------------------------------cf
.cf{
  @include cf;
}

//---------------------------------hide
.hide{
  &__sp{
    @media screen and (max-width: 768px) {
    	display: none !important;
    }
  }
  &__pc{
    @media screen and (min-width: 769px) {
    	display: none !important;
    }
  }
}

//---------------------------------loading
.loading{
  &__wrap{
    padding: 15px;
    text-align: center;
    width: 100%;
  }
  &__inner{
  }
  &__icon{
  }
  &__message{
  }
  &-none{
  }
}

/**********************
  nav
**********************/

.nav{
  &__child{
    z-index: 9999;
  }
}


/**********************
	title
**********************/

.title{
  &__main{
    text-align: center;
    color: $color-base;
    background-color: $color-main;
    line-height: 1.5;
    padding: 0.3em 0.5em;
    font-size: 26px;
    border-bottom: 2px solid $color-border;
    margin-top: 0px;
    margin-bottom: 20px;
    &_size_small{ font-size: 20px; }
    &_color_red{ background-color: $color-accent; }
  }
  &__sub{
    padding-bottom: 10px;
    font-size: 20px;
    background:transparent url(./images/background.png) left bottom repeat-x;
    line-height: 1.5;
    display: inline-block;
    margin: 20px 0;
  }
  &__large{

  }
  &__mini{
    margin-bottom: 10px;
    margin-top: 0;
    padding-bottom: 0px;
    margin-top: 10px;
    background-image: none;
    font-size: 14px;
  }
  &__image{
  	padding: 0px !important;
  	margin: 0px !important;
  	padding-bottom: 10px !important;
  	background: none;
  }
}


/**********************
	wrap
**********************/

.wrap{
  &__flex{
  	@include flex;
  }
  &__full{

  }
  &__main{
    @include cf;
    margin-bottom: 50px;
  }
  &__sub{
	@media screen and (min-width: 769px) {
      width: 80%;
	}
  }
}


/**********************
	text
**********************/

.text{
  &__bold{
    color: $color-main;
    font-weight: bold;
    font-size: 1.2em;
  }
  &__strong{
    color: $color-accent;
    font-weight: bold;
    font-size: 1.2em;
  }
  &__small{
  	font-size: 0.8em;
  }
}


/**********************
	list
**********************/

//---------------------------------list-check
.list-check{
  list-style-type: none;
  padding: 0px;
  &__item{
    &:before{
    	content: "\f058";
    	font-family: FontAwesome;
    	margin-right: 0.5em;
    	color: $color-icon;
    }
  }
}


/**********************
	content
**********************/

//---------------------------------content-box
.content-box{
  margin-bottom: 15px;
  position: relative;
  &__inner{
    padding: 12px;
    height: 100%;
    box-sizing: border-box;
    @include cf;
    box-shadow: $color-border -1px -1px 0 1px inset;
  }
  &__time{
    display: block;
    margin-bottom: 2em;
  }
  &__iconlist{
    i{ color: $color-icon; }
    color: $color-text;
    line-height: 1.7;
  }
  &__title{
    margin-bottom: 10px;
    margin-top: 0px;
  }
  &__btn{
    margin-top: 10px;
    position: relative;
    z-index: 110;
  }
  &__figure{
    padding-left: 0px;
    text-align: center;
  }
  &__link{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}



//---------------------------------content-categories

.content-categories{
  margin-bottom: 1em;
  &__link{
    &:after{
      content: '/';
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
    &:last-child:after{
      display: none;
    }
    a{
      padding: 0.2em 0.5em;
      border: 1px solid $color-main;
      color: $color-main;
      font-size: 10px;
      &:hover{
        color: $color-base;
        background-color: $color-main;
      }
    }
  }
}


//---------------------------------content-table

.content-table{
  width: 100%;
  &__td{
    &_first,&_last{
      padding: 0.3em 0.5em;
      border-bottom: 1px solid $color-border__light;
    }
    &_first{
      font-weight: bold;
      min-width: 7em;
    }
    &_last{
      color: $color-theme;
    }
  }
}


//---------------------------------content-pager
.content-pager {
  text-align: center;
  &__page{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: solid 1px $color-border__light;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    transition: .3s;
    &:hover,&_active{
      background-color: $color-main;
      color: $color-base;
    }
  }
  &__allow{
    margin-right: 10px;
    line-height: 20px;
    cursor: pointer;
    transition: .3s;
    display: inline-block;
    &:hover{
      opacity: .6
    }
  }
}
.pager__page.active {
  background-color: #1276c3;
  color: #fff;
}


/**********************
	sections
**********************/

//---------------------------------section-logo
.section-logo{
  &__inner{
    padding: 10px 5px;
  }
  &__link{
  }
  &__image{
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }
  &__title{
  	font-size: 12px !important;
  	display: inline-block;
  	text-align: center;
  	width: 100%;
  	word-wrap: break-word;
  	margin-bottom: 0px !important;
  	padding-bottom: 0px !important;
  }
}

//---------------------------------section-menu
.section-menu{
  &__inner{
    padding: 10px 5px;
  }
  &__link{
  }
  &__image{
  	max-width: 75px;
  	margin-left: auto;
  	margin-right: auto;
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }
  &__title{
  	font-size: 14px !important;
  	display: inline-block;
  	text-align: center;
  	width: 100%;
  	word-wrap: break-word;
  	margin-bottom: 0px !important;
  	padding-bottom: 0px !important;
  }
  &__price{
  	font-size: 22px;
  	text-align: center;
  	font-weight: bold;
  	color: $color-accent;
  	margin-bottom: 0px;
  }
}

//---------------------------------section-strongs
.section-strongs{
  margin-bottom: 15px;
  border: 1px solid $color-border__light;
  padding-left: 0px !important;
  padding-right: 0px !important;
  @include flex;
  &__box{
    text-align: center;
    position: relative;
    background-color: $color-main;
  }
  &__boxinner{
  	margin-bottom: 0px;
  	position: absolute;
  	left: 50%;
  	top: 50%;
  	transform: translateX(-50%) translateY(-50%);
  	@media screen and (max-width: 768px){
  	  left: auto;
  	  right: 0%;
  	  transform: translateX(-25%) translateY(-50%);
  	}
  }
  &__string{
    margin-bottom: 0px;
    color: $color-base;
    @media screen and (max-width: 768px){
  　　　　display: none;
    }
  }
  &__number{
    color: $color-icon;
    font-size: 48px;
    font-style: italic;
    margin-bottom: 0px;
    line-height: 1.2;
    text-indent: -0.2em;
  }
  &__contents{
    padding: 10px;
  }
  &__title{
    background: none;
    border-bottom: 2px solid $color-border__light;
    display: block;
    color: $color-main;
    padding: 10px !important;
    margin: 0px !important;
  }
  &__description{
    padding: 10px;
    padding-bottom: 0px;
  }
  &:last-child{
    margin-bottom: 0px;
  }
}

//---------------------------------section-header
.section-header{
	margin-bottom: 15px;
  &__title{

  }
  &__description{
    padding-left: 15px;
    padding-right: 15px;
  }
}

//---------------------------------section-tips
.section-tips{
	margin-bottom: 15px;
  &__inner{

  }
  &__icon{
  	color: $color-icon;
  	margin-right: 0.5em;
  }
  &__title{
    font-size: 16px !important;
    margin: 0px !important;
    padding-bottom: 0px;
    color: $color-base;
    background: none;
    background-color: $color-theme;
    width: 100%;
    display: block;
    padding: 10px 15px !important;
    position: relative;
    &:after{
      position: absolute;
      content: '';
      right: 0px;
      top: 0px;
      width: 5px;
      height: 5px;
      border-top: 5px solid $color-base;
      border-right: 5px solid $color-base;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
  &__contents{
    padding: 15px;
    border: 1px solid $color-border;
    border-top: 0px;
    p:last-child{
      margin-bottom: 0px;
    }
  }
}

//---------------------------------section-contents
.section-contents{
	margin-bottom: 15px;
	&__inner{
		padding: 10px;
	}
}

//---------------------------------section-pricebar
.section-pricebar{
  margin-top: 15px;
  &__inner{
    align-items: center;
	background-color: $color-text;
  	border: 1px solid $color-border__light;
  }
  &__titlewrap{
	padding: 10px;
    position: relative;
  }
  &__title{
  	color: $color-base;
  	margin-bottom: 0px;
  	text-align: center;
  	font-size: 20px;
  }
  &__contents{
  	background-color: $color-base;
  }
  &__item{
    padding: 10px;
    position: relative;
  }
  &__iteminner{
  	text-align: center;
  }
  &__label{
    margin-bottom: 5px;
    margin-top: 5px;
  }
  &__contents{
    p{ margin-bottom: 0.2em; }
  }
  &__icon{
    font-style: normal;
    font-size: 30px;
    color: $color-icon;
    z-index: 100;
    font-weight: bold;
    text-align: center;
    display: block;
    transform: translateY(25%);
    @media screen and (min-width: 769px) {
      position: absolute;
      right: -0.25em;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .text__strong{
  	font-size: 2em;
  }
}


/**********************
	contact
**********************/

//---------------------------------section-contact
.section-contact{
  background-color: $color-sub;
  &__header{

  }
  &__title{
    margin-bottom: 0px;
  }
  &__contents{
  	@include cf;
  	padding: 15px 15px 5px;
  }
  &__description{

  }
  &__iconwrap{
    position: relative;
  }
  &__iconinner{
  	padding: 10px;
  	width: 100px;
  	height: 100px;
  	background-color: $color-base;
  	border-radius: 50% 50% 50% 50%;
  	margin-bottom: 15px;
  	margin-left: auto;
  	margin-right: auto;
  	@media screen and (min-width: 769px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      margin-bottom: 0px;
      margin-right: 15px;
    }
  }
  &__icon{
  	@media screen and (min-width: 769px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

//---------------------------------contact-btn
.contact-btn{
	padding: 0px 9px 15px;
  &__item{
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    &_align{
      @media screen and (min-width: 769px){
        &_left{ margin-left: 0px; }
        &_right{ margin-right: 0px; }
      }
    }
  }
  &__link{
  	@media screen and (max-width: 768px){
  		font-size: 1.2em !important;
  	}
    &_color{
      &_red{
      	background-color: $color-accent;
      	&:hover{
          background-color: transparent;
          border-color: $color-accent;
          color: $color-accent !important;
      	}
      }
      &_blue{
      	background-color: $color-main;
      	&:hover{
          background-color: transparent;
          border-color: $color-main;
          color: $color-main !important;
      	}
      }
      &_green{
      	background-color: $color-safety;
      	&:hover{
          background-color: transparent;
          border-color: $color-safety;
          color: $color-safety !important;
      	}
      }
    }
  }
  &__icon{
    margin-right: 0.5em;
    img{ max-height: 1em; }
  }
}


/**********************
	サイト固有
**********************/

.borders{
  border-top: 1px solid $color-border__light;
  border-left: 1px solid $color-border__light;
  &__item{
  	border-right: 1px solid $color-border__light;
  	border-bottom: 1px solid $color-border__light;
  }
}

.sidebar-banner {
	margin-bottom: 30px;
}

.storeList{
  &__title{
    font-size: 24px;
    color: $color-main;
  }
}


/**********************
  後々調整
**********************/
.sub-header__content .navbar-default .navbar-nav > li > a{
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.sub-header{
  &__content{
    >span{
      &::after{
        content :'\03e';
        padding: 0 0.5em;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
    }
  }
}


/**********************
  search
**********************/

.search{
  &__wrap{
    position: relative;
    padding: 0px 15px;
    border: 1px solid $color-border__light;
    margin-bottom: 30px;
    .open__btn{
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      display: block;
      border-bottom: 1px solid $color-border__light;
      font-size: 18px;
      font-weight: bold;
      color: $color-main;
    }
  }
  &__jirei{
    width: 100%;
    display: inline-block;
  }
}

.search-loader{
  background-color: rgba($color-base,.9);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all .5s ease;
  &_off{
    top: -100%;
    opacity: 0;
  }
  &__inner{
    padding: 15px;
    @include cf;
    @include flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 600px;
    width: 100%;
  }
  &__title{
    margin-bottom: 0px;
    font-size: 16px;
    text-align: center;
  }
  &__content{

  }
  &__bar{
    background-color: $color-border__light;
    height: 20px;
    position: relative;
    font-weight: bold;
    border-radius: 10px;
  }
  &__innerbar{
    background-color: $color-main;
    transition: all .5s ease;
    height: 100%;
    border-radius: 10px;
  }
  &__text{
    position: absolute;
    top: 0px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 1.65;
  }
}


/**********************
  archives
**********************/

.archives{
  padding-bottom: 50px;
}


/**********************
  franchise
**********************/
.fc{
  &__content{}
  &__text_wrap{
    padding: 2em 0;
  }
  &__title{
    font-size:18px;
  }
  &__contact{
    table{
      width: 100%;
      th,td{
        width: 100%;
        padding: 0.5em ;
        border-bottom: solid 1px $color-border;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }
      th{
        @media screen and (min-width: 769px) {
          width: 20%;
        }
        background-color: $color-sub;
        span{
          color: $color-accent;
        }
      }
      td{
        @media screen and (min-width: 769px) {
          width: 80%;
        }
        input{
          &[type="text"],
          &[type="tel"],
          &[type="email"],{
            width: 100%;
            padding: 0.2em;
          }
        }
        textarea{
          width: 100%;
          padding: 0.2em;
        }
      }
    }
    input{
      &[type="submit"]{
        display: block;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__tokucyou{
    table{
      border: solid 1px $color-border;
      width: 100%;
      th,td{
        padding: 0.4em;
        border-bottom: solid 1px $color-border__light;
      }
      tr{
        @media screen and (min-width: 769px){
          &:last-child{
            th,td{
              border-bottom: none;
              background-color: $color-sub;
            }
          }
        }
      }
    }
  }
}


/**********************
  maintenance and repair
**********************/
.mainterepair{
  &__strong{
    padding: 15px 0;
    text-align: center;
    font-size: 18px;
  }
  th{
    padding: .4em;
  }
}





.alignitems{
  &__center{
    align-items: center;
  }
}

.navbar{
  .menu{
    list-style-type: none;
    padding-left: 0;
    &>li{
      &>a{
        font-size:1.1em;
        border-bottom: solid 1px $color-border__light;
        display: block;
        padding: 0.4em 0;
      }
    }
    ul{
      padding: 0.5em 0 0 0.5em;
      list-style-position: inside;
      li{
        margin-bottom: 0.5em;
      }
    }
  }
}

/**********************
  parts
**********************/

.label__square{
  display: inline-block;
  padding: 0.2em 0.5em;
  color: $color-main;
  border: 1px solid $color-main;
  margin-right: 0.5em;
  margin-top: .25em;
  margin-bottom: .25em;
}

.mailing{
  .text__wrap{
    margin-bottom: 30px;
  }
  .hiw{
    &__subscliption{
      padding: 10px;
      border: solid 1px darken($color-border__light,22);
    }
    &__inner{
      padding-top: 30px;
    }
  }
}


.table__full{
  width: 100%;
  th,td{
    border: solid 1px $color-border__light;
    padding: 0.6em 0.2em;
    text-align: center;
  }
  th{
    background-color: $color-border;
    color: $color-base;
  }
}

.border{
  &-top{
    border-top: solid 1px $color-border;
  }
}

.pr{
  &-20{
    @media screen and (min-width: 769px){
      &_pc{
        padding-right: 20px;
      }
    }
  }
}

.mb{
  &-30{
    margin-bottom: 30px;
  }
  &-60{
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px){
  .btn{
    &.summary{
      &__more{
        padding: 15px 0!important;
        // position: absolute;
        // left: 0;
        // bottom: 0;
      }
    }
  }
  .summary{
    // padding-bottom: 0;
    // padding-left: 0;
    // padding-right: 0;
    // &__text{
    //   padding-bottom: 40px;
    // }
  }
  #home{
   .repair-case{
    // display: flex;
    // flex-wrap: wrap;
    // align-content: space-between;
    .box{
      padding-bottom: 2.5em;
    }
    .btn{
      // position: static;
      padding: 5px 0!important;
      line-height: 1.7;
      height: auto;
    }
   }
  }
}

.qa{
  &__question,&__answer{
    @include flex;
    &_text{
      width: 90%;
    }
    &_icon{
      position: relative;
      width: 10%;
      span{
        font-size: 2em;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 2em;
        height: 2em;
        text-align: center;
        background-color: $color-main;
        color: $color-base;
        border-radius: 50% 50% 50% 50%;
        line-height: 1.8;
        display: block;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    @media screen and (max-width: 768px){
      &_text{
        width: 80%;
      }
      &_icon{
        width: 20%;
      }
    } 
  }
  &__question_icon span{
    background-color: $color-accent;
  }
  &__question_text{
    @include font-size(30);
    line-height: 1.5;
    padding: 10px !important;
    margin-bottom: 0px;
    border-bottom: 2px solid $color-border__light;
  }
  &__answer_text{
    @include font-size(26);
    text-align: left;
    padding: 10px !important;
  }
  &__inner{
    margin-bottom: 30px;
  }
  &__title_main{
    text-align: center;
    color: $color-base;
    background-color: $color-main;
    line-height: 2;
    font-size: 20px;
    @include box-shadow($color-theme,1,1px 1px 0 1px);
  }
  &__title{
    display: block;
    margin-bottom: 10px;
    &_howto{
      background-color: $color-accent;
    }
    &_sub{
      margin-bottom: 0!important;
      display: inline-block;
      width: 100%;
    }
  }
}

.qa-howto{
  padding-top: 30px;
}

.mokuji{
  margin-bottom: 60px;
  &__title{
    display: block;
    background:none;
    text-align: center;
  }
  &__inner{
    background-color: lighten($color-border__light, 3%);
    border: 1px solid $color-border__light;
    padding: 1em;
    display: inline-block;
  }
  &__ul{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
  }
  &__li{
    padding: 1em 1em;
    border-top: 1px solid $color-border__light;
    &:before{
      content: "\f105";
      font-family: FontAwesome;
      margin-right: 0.5em;
      color: $color-icon;
    }
    &:first-child{
      border: 0px;
    }
  }
}


.yuzo_related_post{
  padding: 0px !important;
  margin: 0px !important;
}

.nav-sp{
  .nav__has_child{
    .nav__child{
      display: block;
    }
  }
}
